import React, { useState, useEffect } from 'react';

import Header from '../../components/Headers/Header';
import MenuLateral from '../../components/Menus/MenuLateral/MenuLateral';
import Footer from '../../components/Footer/Footer';

function NewSPEIINTransaction() {

    return (
        <div>
            <Header isLogin={true} />
            <div className="container-fluid2 position-absolute space-admin">
                <div className='row'>
                    <div className='col-sm-3'>
                        <div className="right-shadow2 padding-admin">
                            <MenuLateral selected={"9"} />
                        </div>
                    </div>
                    <div className='col-sm-9'>
                        <div className="left-shadow2">
                            <div className="card-body">
                                <div className='card-sub-title-bg2'>
                                    <div className="containerTitleAdmin">
                                        <p className='tilteAdmin'>Nueva Transacción SPEI IN</p>
                                    </div>
                                </div>
                                <div className="bg-container-body">

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default NewSPEIINTransaction