import React from 'react';
import Modal from 'react-bootstrap/Modal';

function Decline({ show, handleClose }) {
    return (
        <div>
            <Modal show={show} onHide={handleClose} size="md">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5 className="modal-title">Motivo del Rechazo</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="card card-caratula">
                        <div className="row-form-text0 mb-3">
                            <label className="col-form-label">Razon:</label>
                            <textarea class="form-control" ></textarea>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={handleClose} type="button" className="btn btn-primary2 btnModal">Registrar</button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default Decline