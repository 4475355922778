import React, { useEffect, useState } from 'react'

import Header from '../../components/Headers/Header';
import MenuLateral from '../../components/Menus/MenuLateral/MenuLateral';
import Footer from '../../components/Footer/Footer';
import Table from '../../components/Table/Table';
import Message from '../../components/Message/Message';
import AddAccountUsers from '../../components/Modals/AddAccountUsers';

import { useNavigate } from 'react-router-dom';
import { fetchData, postData } from '../../services/Request'
import Encrypter from '../../services/Encrypter';
import { useParams } from 'react-router-dom';

function UpdateUsers() {
    const { id } = useParams();
    const navigate = useNavigate();

    const tableHeaders = ['Clabe', 'Empresa', 'CTA Comision', '%'];
    const tableStyles = ['textStyle', 'textStyle', 'textStyle', 'textStyle'];
    const [tableData, setTableData] = useState([]);

    const [pagination, setPagination] = useState({
        itemsPerPage: 50,
        totalItems: 1,
        currentPage: 1
    });

    const [updateData, setUpdateData] = useState(1);
    const reload = () => {
        setUpdateData(updateData + 1);
    }

    const setCurrentPage = (newPage) => {
        setPagination((prevData) => ({
            ...prevData,
            currentPage: newPage
        }));
    }

    const [isSaveDataDisabled, setIsSaveDataDisabled] = useState(false);
    const [alert, setAlert] = useState({ tipo: '', msn: '', show: false });
    const closeAlert = () => {
        setAlert({ tipo: '', msn: '', show: false });
    };

    function Options({ options }) {
        return (
            options.map(option => <option key={option.id} value={option.id}> {option.value} </option>)
        );
    }

    const [newUserData, setNewUserData] = useState({
        id: 0,
        nombre: '',
        correo: '',
        loginPass: '',
        opsPass: '',
        roleId: 0,
        clienteId: 0,
        empresaId: 0,
        cuentaId: 0,
        cuentaCobroId: 0,
        porcentajeCobro: 0,
        multicuentas: []
    });

    const [udnsToSelect, setUdnsToSelect] = useState([{
        id: 0,
        value: 'Seleccione la UDN'
    }]);

    const [rolesToSelect, setRolesToSelect] = useState([{
        id: 0,
        value: 'Seleccione el role del usuario'
    }]);

    const [companiesList, setCompaniesList] = useState([]);
    const loadCompanies = (localClientId, localRoleId, localEmpresaId) => {
        setCompaniesList([]);
        localRoleId = parseInt(localRoleId);
        if (localRoleId === 30 || localRoleId === 50 || localRoleId === 60 || localRoleId === 80 || localRoleId === 90 || localRoleId === 100) {
            const userData = JSON.parse(localStorage.getItem('userData'))
            const token = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, userData.token);
            const headers = { headers: { 'authorization': 'APIAuth ' + process.env.REACT_APP_API_KEY + ':' + token } }

            var useLocalId = newUserData.clienteId;
            if (localClientId)
                useLocalId = localClientId;
            if (useLocalId) {
                //console.log('udn: ', useLocalId);
                fetchData(process.env.REACT_APP_ACCOUNT_COMPANIES + useLocalId + "/?token=" + userData.token, headers)
                    .then(({ response, error }) => {
                        if (error) {
                            console.log(error);
                            const responseErrors = error.response.data.errors.errors;
                            if (error.response.status === 401) {
                                localStorage.removeItem('userData');
                                navigate('/');
                                return;
                            } else if (responseErrors !== undefined) {
                                setAlert({ tipo: 'danger', msn: responseErrors.map(error => error.message).join('\n'), show: true });
                            } else {
                                console.log(error);
                                setAlert({ tipo: 'danger', msn: 'Error en el sistema', show: true });
                            }
                        }

                        if (response) {
                            //console.log('udns: ', response);
                            const udns = response.empresas.map(item => ({
                                id: item.idEmpresa, //idUdn,
                                value: item.razonSocial,
                                //clientId: item.id
                            }));
                            setCompaniesList(udns);

                            /// ESTO ES UN ERROR, si el usuario tiene empresa asignada, entonces, ir por ella ... 
                            // if(udns[0]) {
                            //     setNewUserData((prevData) => ({
                            //         ...prevData,
                            //         'empresaId': udns[0].id
                            //     }));
                            //     loadAccounts(udns[0].id);
                            // }
                            if (localEmpresaId) {
                                console.log(localEmpresaId);
                                loadAccounts(localEmpresaId);
                            } else {
                                // aqui si a lo mejor cargar las de udns[0]
                                if (udns[0])
                                    loadAccounts(udns[0].id);
                            }
                        }
                    });
            }
        }
    }

    const [multiAccounts, setMultiAccounts] = useState([]);
    const [cuentaCobroOps, setCuentaCobroOps] = useState([]);
    const [accountsList, setAccountsList] = useState([]);
    const loadAccounts = (localCompanyId) => {
        setAccountsList([]);
        // if((newUserData.empresaId>0) && (newUserData.roleId === '60' || newUserData.roleId === '80')) {
        const userData = JSON.parse(localStorage.getItem('userData'))
        const token = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, userData.token);
        const headers = { headers: { 'authorization': 'APIAuth ' + process.env.REACT_APP_API_KEY + ':' + token } }

        var useLocalId = newUserData.empresaId;
        if (localCompanyId)
            useLocalId = localCompanyId;

        if (localCompanyId) {
            fetchData(process.env.REACT_APP_ACCOUNTS_COMPANY + useLocalId + "/?token=" + userData.token, headers)
                .then(({ response, error }) => {
                    if (error) {
                        console.log(error);
                        const responseErrors = error.response.data.errors.errors;
                        if (error.response.status === 401) {
                            localStorage.removeItem('userData');
                            navigate('/');
                            return;
                        } else if (responseErrors !== undefined) {
                            setAlert({ tipo: 'danger', msn: responseErrors.map(error => error.message).join('\n'), show: true });
                        } else {
                            console.log(error);
                            setAlert({ tipo: 'danger', msn: 'Error en el sistema', show: true });
                        }
                    }

                    if (response) {
                        const cuentas = response.map(item => ({
                            id: item.id, //idUdn,
                            value: item.accountNumber,
                            //clientId: item.id
                        }));
                        setAccountsList(cuentas);
                        //console.log(udns);

                        // AQUI HAY UN ERROR... deberia ser ... si el usuario tiene cuenta, ir por ella, o no seleccionar nada
                        // if(cuentas[0])
                        //     setNewUserData((prevData) => ({
                        //         ...prevData,
                        //         'cuentaId': cuentas[0].id,
                        //     }));

                    }
                });
        }
        // }
    }

    const saveData = () => {
        setIsSaveDataDisabled(true);
        setAlert({ tipo: '', msn: '', show: false });
        const userData = JSON.parse(localStorage.getItem('userData'))
        const token = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, userData.token);
        const headers = { headers: { 'authorization': 'APIAuth ' + process.env.REACT_APP_API_KEY + ':' + token } }

        const dataToUpdate = {
            id: newUserData.id,
            username: newUserData.nombre,
            email: newUserData.correo,
            pwdAccess: newUserData.loginPass,
            opsPwd: newUserData.opsPass,
            idClient: parseInt(newUserData.clienteId),
            idCompany: newUserData.empresaId ? parseInt(newUserData.empresaId) : null,
            idAccount: newUserData.cuentaId ? parseInt(newUserData.cuentaId) : null,
            idRole: newUserData.roleId.toString(),
            multiAccounts: newUserData.multicuentas,
            depositCost: parseFloat(newUserData.porcentajeCobro),
            chargeAccount: parseInt(newUserData.cuentaCobroId)
        }
        const strPayload = JSON.stringify(dataToUpdate);
        const cryptPayload = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, strPayload);
        const payload = { userdata: cryptPayload };

        postData(process.env.REACT_APP_AUTH_USERS + newUserData.id + "?token=" + userData.token, payload, headers)
            .then(({ response, error }) => {
                if (error) {
                    setIsSaveDataDisabled(false);
                    console.log(error);
                    const responseErrors = error.response.data.errors.errors;
                    if (error.response.status === 401) {
                        localStorage.removeItem('userData');
                        navigate('/');
                        return;
                    } else if (responseErrors !== undefined) {
                        setAlert({ tipo: 'danger', msn: responseErrors.map(error => error.message).join('\n'), show: true });
                    } else {
                        console.log(error);
                        setAlert({ tipo: 'danger', msn: 'Error en el sistema', show: true });
                    }
                }

                if (response) {
                    //console.log(response);
                    setAlert({ tipo: 'success', msn: 'Datos actualizados correctamente', show: true });
                }
            });
    }

    const handleChangeRole = (e) => {
        const { id, value } = e.target;
        setAlert({ tipo: '', msn: '', show: false });

        //console.log('changing', value, newUserData.roleId);
        if (parseInt(value) === 80) {
            //console.log(newUserData.roleId);
            if ((parseInt(value) !== parseInt(newUserData.roleId)) && (parseInt(newUserData.roleId) !== 80)) {
                setAlert({ tipo: 'warning', msn: 'No puede cambiar un usuario actual a rol de renta', show: true });
                return;
            }
        }
        setNewUserData((prevData) => ({
            ...prevData,
            [id]: value
        }));
        setUI(value);
        loadCompanies(newUserData.clienteId, value, newUserData.empresaId);
        //loadAccounts(newUserData.empresaId);
    }

    const handleChangeUDN = (e) => {
        const { id, value } = e.target;
        setAlert({ tipo: '', msn: '', show: false });

        setUI(newUserData.roleId);
        loadCompanies(value, newUserData.roleId, newUserData.empresaId);
        //loadAccounts(newUserData.empresaId);
        setNewUserData((prevData) => ({
            ...prevData,
            [id]: value
        }));
    }

    const handleChangeCompany = (e) => {
        const { id, value } = e.target;
        setAlert({ tipo: '', msn: '', show: false });

        loadAccounts(value);
        setNewUserData((prevData) => ({
            ...prevData,
            [id]: value
        }));
    }

    const handleChanges = (e) => {
        const { id, value } = e.target;
        setAlert({ tipo: '', msn: '', show: false });

        setNewUserData((prevData) => ({
            ...prevData,
            [id]: value
        }));
    }

    const handleChangeMultiAccounts = (e) => {
        let value = Array.from(e.target.selectedOptions, option => option.value);

        setNewUserData((prevData) => ({
            ...prevData,
            'multicuentas': value
        }));
    }

    const [showCompany, setShowCompany] = useState(false);
    const [showAccount, setShowAccount] = useState(false);
    const [showMultiAccounts, setShowMultiAccounts] = useState(false);
    const [blockDeepChanges, setBlockDeepChanges] = useState(false);

    useEffect(() => {
        //cargamos datos base (udns, roles), y datos del usuario... 
        function getRoles() {
            const userData = JSON.parse(localStorage.getItem('userData'))
            const token = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, userData.token);
            const headers = { headers: { 'authorization': 'APIAuth ' + process.env.REACT_APP_API_KEY + ':' + token } }

            fetchData(process.env.REACT_APP_AUTH_ROLES + "?token=" + userData.token, headers)
                .then(({ response, error }) => {
                    if (error) {
                        const responseErrors = error.response.data.errors.errors;
                        if (error.response.status === 401) {
                            localStorage.removeItem('userData');
                            navigate('/');
                            return;
                        } else if (responseErrors !== undefined) {
                            setAlert({ tipo: 'danger', msn: responseErrors.map(error => error.message).join('\n'), show: true });
                        } else {
                            setAlert({ tipo: 'danger', msn: 'Error en el sistema', show: true });
                        }
                    }

                    if (response) {
                        const roles = response.roles.map(item => ({
                            id: item.id,
                            value: item.nombre
                        }));
                        setRolesToSelect(roles);
                    }
                });
        }
        function getUdns() {
            const userData = JSON.parse(localStorage.getItem('userData'))
            const token = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, userData.token);
            const headers = { headers: { 'authorization': 'APIAuth ' + process.env.REACT_APP_API_KEY + ':' + token } }

            fetchData(process.env.REACT_APP_UDNS + "?token=" + userData.token, headers)
                .then(({ response, error }) => {
                    if (error) {
                        const responseErrors = error.response.data.errors.errors;
                        if (error.response.status === 401) {
                            localStorage.removeItem('userData');
                            navigate('/');
                            return;
                        } else if (responseErrors !== undefined) {
                            setAlert({ tipo: 'danger', msn: responseErrors.map(error => error.message).join('\n'), show: true });
                        } else {
                            setAlert({ tipo: 'danger', msn: 'Error en el sistema', show: true });
                        }
                    }

                    if (response) {
                        const udns = response.udns.map(item => ({
                            id: item.idUdn,
                            value: item.descripcion
                        }));
                        setUdnsToSelect(udns);
                    }
                });
        }
        function getUser() {
            const userData = JSON.parse(localStorage.getItem('userData'))
            const token = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, userData.token);
            const headers = { headers: { 'authorization': 'APIAuth ' + process.env.REACT_APP_API_KEY + ':' + token } }

            fetchData(process.env.REACT_APP_AUTH_USERS + id + "?token=" + userData.token, headers)
                .then(({ response, error }) => {
                    if (error) {
                        const responseErrors = error.response.data.errors.errors;
                        if (error.response.status === 401) {
                            localStorage.removeItem('userData');
                            navigate('/');
                            return;
                        } else if (responseErrors !== undefined) {
                            setAlert({ tipo: 'danger', msn: responseErrors.map(error => error.message).join('\n'), show: true });
                        } else {
                            setAlert({ tipo: 'danger', msn: 'Error en el sistema', show: true });
                        }
                    }

                    if (response) {
                        setNewUserData({
                            id: response.idUsuario,
                            nombre: response.nombre ? response.nombre : '',
                            correo: response.email ? response.email : '',
                            loginPass: '',
                            opsPass: '',
                            roleId: response.idRole ? response.idRole : 0,
                            clienteId: response.idUdn ? response.idUdn : 0,
                            empresaId: response.idEmpresa ? response.idEmpresa : 0,
                            cuentaId: response.idCuenta ? response.idCuenta : 0,
                            cuentaCobroId: response.cuentaCobroId ? response.cuentaCobroId : 0,
                            porcentajeCobro: response.porcentajeCobro ? response.porcentajeCobro : 0,
                            multicuentas: []
                        });

                        if (response.idRole) {
                            setUI(response.idRole);
                            loadCompanies(response.idUdn, response.idRole, response.idEmpresa);
                        }
                        if (response.idRole === 80) {
                            //traemos datos de las multi cuentas
                            fetchData(process.env.REACT_APP_ACCOUNT_MULTIACCOUNT + id + "?token=" + userData.token, headers)
                                .then(({ response, error }) => {
                                    if (error) {
                                        const responseErrors = error.response.data.errors.errors;
                                        if (error.response.status === 401) {
                                            localStorage.removeItem('userData');
                                            navigate('/');
                                            return;
                                        } else if (responseErrors !== undefined) {
                                            setAlert({ tipo: 'danger', msn: responseErrors.map(error => error.message).join('\n'), show: true });
                                        } else {
                                            setAlert({ tipo: 'danger', msn: 'Error en el sistema', show: true });
                                        }
                                    }

                                    if (response) {
                                        const multiCuentasOps = response.map(item => ({
                                            id: item.idCuentaAsignada,
                                            value: item.clabeCuenta
                                        }));
                                        setMultiAccounts(multiCuentasOps);
                                        var multiCuentas = []
                                        response.map(item => (
                                            multiCuentas.push(item.idCuentaAsignada)
                                        ));
                                        setNewUserData((prevData) => ({
                                            ...prevData,
                                            'multicuentas': multiCuentas
                                        }));
                                        const opsCuentaCobro = response.map(item => ({
                                            id: item.idCuentaAsignada,
                                            value: item.clabe
                                        }));
                                        setCuentaCobroOps(opsCuentaCobro);
                                        setNewUserData((prevData) => ({
                                            ...prevData,
                                            'cuentaCobroId': response[0].idCuentaCobroComision,
                                            'porcentajeCobro': response[0].comisionAbono
                                        }));
                                    }
                                });
                        } else {
                            // if(response.idEmpresa) {
                            //     console.log('idEmpresa es: ', response.idEmpresa);
                            //     loadAccounts(response.idEmpresa);
                            // }
                        }
                    }
                });
        }
        getUdns();

        getRoles();
        getUser();
    }, [id, navigate]);

    const setUI = (roleId) => {
        switch (parseInt(roleId)) {
            case 30:
            case 50:
            case 90:
            case 100:
                setBlockDeepChanges(false);
                setShowCompany(true);
                setShowAccount(false);
                setShowMultiAccounts(false);
                return;
            case 60:
            case 98:
                setBlockDeepChanges(false);
                setShowCompany(true);
                setShowAccount(true);
                setShowMultiAccounts(false);
                return;
            case 80:
                setBlockDeepChanges(true);
                setShowCompany(true);
                setShowAccount(false);
                setShowMultiAccounts(true);
                return;
            default:
                setBlockDeepChanges(false);
                setShowCompany(false);
                setShowAccount(false);
                setShowMultiAccounts(false);
                return;
        }
    }

    const [showAdd, setShowAdd] = useState(false);
    const handleCloseAdd = () => setShowAdd(false);
    const handleShowAdd = () => setShowAdd(true);

    return (
        <div>
            <Header isLogin={true} />
            <div className="container-fluid2 position-absolute space-admin">
                <div className='row'>
                    <div className='col-sm-3'>
                        <div className="right-shadow2 padding-admin">
                            <MenuLateral selected={"3"} />
                        </div>
                    </div>
                    <div className='col-sm-9'>
                        <div className="left-shadow2">
                            <div className='card-sub-title-bg2'>
                                <div className='row'>
                                    <div className="col-sm-8 containerTitleAdmin">
                                        <p className='tilteAdmin'>Actualizar Usuario -  {newUserData.nombre}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-container-admin-card">
                                <div className="card card-caratula space-update">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className='space-fila'>
                                                    <label className="form-label">Nombre del usuario</label>
                                                    <input type="text" className="form-control txtControl" id="nombre" onChange={handleChanges} value={newUserData.nombre} />
                                                </div>
                                                <div className='space-fila'>
                                                    <label className="form-label">Correo electrónico</label>
                                                    <input type="text" className="form-control txtControl" id="correo" onChange={handleChanges} value={newUserData.correo} />
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div>
                                                    <label className="form-label">Ingrese la contraseña de acceso</label>
                                                    <input type="text" className="form-control txtControl" id="loginPass" onChange={handleChanges} value={newUserData.loginPass} placeholder='<Actualizar contraseña>' />
                                                </div>
                                                <div>
                                                    <label className="form-label">Ingrese la contraseña de operaciones</label>
                                                    <input type="text" className="form-control txtControl" id="opsPass" onChange={handleChanges} value={newUserData.opsPass} placeholder='<Actualizar contraseña>' />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card card-caratula space-update">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className='col-sm-6'>
                                                <label className="form-label space-label">Seleccione la UDN sobre la que hará operaciones el usuario:</label>
                                            </div>
                                            <div className='col-sm-6'>
                                                <select className="form-select" id="clienteId" onChange={handleChangeUDN} value={newUserData.clienteId} disabled>
                                                    <Options options={udnsToSelect} />
                                                </select>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className='col-sm-6'>
                                                <label className="form-label space-label">Seleccione el rol del usuario</label>
                                            </div>
                                            <div className='col-sm-6'>
                                                <select className="form-select" id="roleId" onChange={handleChangeRole} value={newUserData.roleId} disabled={blockDeepChanges}>
                                                    <Options options={rolesToSelect} />
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card card-caratula space-update">
                                    <div className="card-body space-label">
                                        <div className='row'>
                                            <div className="d-flex justify-content-between">
                                                <label className="form-label">Cuentas</label>
                                                <p><a className="add" href="#" onClick={handleShowAdd}>+</a></p>
                                            </div>
                                            <div className='cuentasTable'>
                                                <Table headers={tableHeaders} data={tableData} styles={tableStyles} itemsPerPage={pagination.itemsPerPage} isMenu={false} /*link={link}*/
                                                    currentPage={pagination.currentPage} setCurrentPage={setCurrentPage} totalPages={Math.ceil(pagination.totalItems / pagination.itemsPerPage)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <AddAccountUsers show={showAdd} handleClose={handleCloseAdd} reload={reload} />
            <Footer />
        </div>
    )
}

export default UpdateUsers
