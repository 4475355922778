import React from 'react';
import Modal from 'react-bootstrap/Modal';

function AddAccountUsers({ show, handleClose, reload }) {
    return (
        <div>
            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5 className="modal-title">UDN - FIJA</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="card card-caratula">
                        <div className="card-body">
                            <div className='row space-fila2'>
                                <div className='col-sm-6'>
                                    <label className="form-label">Empresa</label>
                                    <select className="form-select">
                                        <option>seleccionar una opcion</option>
                                    </select>
                                </div>
                                <div className='col-sm-6'>
                                    <label className="form-label">Cuenta</label>
                                    <select className="form-select">
                                        <option>seleccionar una opcion</option>
                                    </select>
                                </div>
                            </div>
                            <div className='row space-fila2'>
                                <div className='col-sm-6'>
                                    <label className="form-label">Comision</label>
                                    <input type="text" className="form-control txtControl" />
                                </div>
                                <div className='col-sm-6'>
                                    <label className="form-label">Cuenta Comision</label>
                                    <select className="form-select">
                                        <option>seleccionar una opcion</option>
                                    </select>
                                </div>
                            </div>

                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-primary2 btnModal" onClick={handleClose} >Guardar</button>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default AddAccountUsers