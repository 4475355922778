import React, { useState } from 'react';

import Header from '../../components/Headers/Header';
import MenuLateral from '../../components/Menus/MenuLateral/MenuLateral';
import Footer from '../../components/Footer/Footer';
import Decline from '../../components/Modals/Decline';

function SPEITransactions() {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <div>
            <Header isLogin={true} />
            <div className="container-fluid2 position-absolute space-admin">
                <div className='row'>
                    <div className='col-sm-3'>
                        <div className="right-shadow2 padding-admin">
                            <MenuLateral selected={"8"} />
                        </div>
                    </div>
                    <div className='col-sm-9'>
                        <div className="left-shadow2">
                            <div className="card-body">
                                <div className='card-sub-title-bg2'>
                                    <div className="containerTitleAdmin">
                                        <p className='tilteAdmin'>Transacciones SPEI</p>
                                    </div>
                                </div>
                                <div className="bg-container-body">
                                    <div className="card card-caratula containerCard">
                                        <div className="card-body">
                                            <form className="row row-form-text0">
                                                <div className="col-auto">
                                                    <label className="form-control-plaintext">Clave de Rastreo:</label>
                                                </div>
                                                <div className="col-auto">
                                                    <input type="text" className="form-control" id="inputClave" placeholder="Clave" />
                                                </div>
                                                <div className="col-auto">
                                                    <button type="submit" className="btn btn-trans">Buscar Clave</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="card card-caratula containerCard">
                                        <div className="card-body">
                                            <h5 className="text-3">Datos de la Transferencia</h5>
                                            <div className="row row-form-text0 mb-2">
                                                <div className="col-md-4">
                                                    <label className="col-form-label">Estatus de la Transferencia</label>
                                                    <input type="text" className="form-control txtControl" value={"ESTATUS"} readOnly />
                                                </div>
                                                <div className="col-md-4">
                                                    <label className="col-form-label">Fecha</label>
                                                    <input type="date" className="form-control txtControl" value={"2023-12-13"} readOnly />
                                                </div>
                                                <div className="col-md-4">
                                                    <label className="col-form-label">Clave</label>
                                                    <input type="text" className="form-control txtControl" value={"xxxxxxxxxx"} readOnly />
                                                </div>
                                            </div>
                                            <div className="row row-form-text0 mb-2">
                                                <div className="col-md-4">
                                                    <label className="col-form-label">Cuenta Origen</label>
                                                    <input type="text" className="form-control txtControl" value={"cuenta - xxxxxxxxxxxx"} readOnly />
                                                </div>
                                                <div className="col-md-4">
                                                    <label className="col-form-label">Cuenta Destino</label>
                                                    <input type="text" className="form-control txtControl" value={"cuenta - xxxxxxxxxxxx"} readOnly />
                                                </div>
                                                <div className="col-md-4">
                                                    <label className="col-form-label">Numero de Referencia</label>
                                                    <input type="text" className="form-control txtControl" value={"xxxxxxxxxx"} readOnly />
                                                </div>
                                            </div>
                                            <div className="row row-form-text0">
                                                <div className="col-md-4">
                                                    <label className="col-form-label">Concepto</label>
                                                    <input type="text" className="form-control txtControl" value={"Concepto"} readOnly />
                                                </div>
                                                <div className="col-md-4">
                                                    <label className="col-form-label">Monto</label>
                                                    <input type="text" className="form-control txtControl" value={"$ 000"} readOnly />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card card-caratula containerCard">
                                        <div className="card-body">
                                            <h5 className="text-3">Acciones</h5>
                                            <div className="row row-form-text0">
                                                <div className="col-md-4">
                                                    <div className="d-grid gap-2 mx-auto buttonAdmin">
                                                        <button className="btnAceptar" type="button" >Aceptar</button>
                                                    </div>
                                                </div>
                                                <div className="col-md-4"></div>
                                                <div className="col-md-4">
                                                    <div className="d-grid gap-2 mx-auto buttonAdmin">
                                                        <button className="btnRechazar" type="button" onClick={handleShow}>Rechazar</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            <Decline show={show} handleClose={handleClose} />
        </div>
    )
}

export default SPEITransactions