import React, { useState } from 'react'

//import icon from '../../../assets/icons/Vector.svg'

function MenuLateral({ selected }) {
    const userData = JSON.parse(localStorage.getItem('userData'));
    const userInitials = userData.userName.split(" ").map((n) => n[0]).join("");

    const [selectedOption, setSelectedOption] = useState(null);

    const handleOptionClick = (option) => {
        setSelectedOption(option);
    };

    /*
                                <li className={selected === '7' ? 'selected' : 'menu-lateral-li'}
                                onClick={() => handleOptionClick('7')}>
                                    <a className="menu-lateral" href="/administrarTarjetahabientes">Administrar Tarjetahabientes</a>
                                </li>
    */

    return (
        <div>
            <div className="collapse collapse-horizontal show" id="collapseLateral">
                <div className="container-menuLateral">
                    <div className='card-body-menu-bg'>
                        <div className='row'>
                            <div className='col-sm-3'>
                                <button className="btn-profil-admin" type="button">{userInitials}</button>
                            </div>
                            <div className='col-sm-9 content-headar-menu'>
                                <label className="title-menu-lateral">{userData.userName}</label>
                                <label className="sub-title-menu-lateral">Administrador</label>
                            </div>
                        </div>
                    </div>

                    <div>
                        <ul className="menu-lateral">
                            <li className={selected === '1' ? 'selected' : 'menu-lateral-li'}
                                onClick={() => handleOptionClick('1')}>
                                <a className="menu-lateral" href="/administrarudns">Administrar UDNs</a>
                            </li>
                            <li className={selected === '2' ? 'selected' : 'menu-lateral-li'}
                                onClick={() => handleOptionClick('2')}>
                                <a className="menu-lateral" href="/empresas">Administrar Empresas</a>
                            </li>
                            <li className={selected === '5' ? 'selected' : 'menu-lateral-li'}
                                onClick={() => handleOptionClick('5')}>
                                <a className="menu-lateral" href="/personasfisicas">Administrar Personas Fisicas</a>
                            </li>
                            <li className={selected === '4' ? 'selected' : 'menu-lateral-li'}
                                onClick={() => handleOptionClick('4')}>
                                <a className="menu-lateral" href='/administrarCuentas'>Administrar Cuentas</a>
                            </li>
                            <li className={selected === '3' ? 'selected' : 'menu-lateral-li'}
                                onClick={() => handleOptionClick('3')}>
                                <a className="menu-lateral" href="/administrarUsuarios">Administrar Usuarios</a>
                            </li>
                            <li className={selected === '6' ? 'selected' : 'menu-lateral-li'}
                                onClick={() => handleOptionClick('6')}>
                                <a className="menu-lateral" href="/administrarTarjets">Administrar Tarjetas</a>
                            </li>
                            <li className={selected === '7' ? 'selected' : 'menu-lateral-li'}
                                onClick={() => handleOptionClick('7')}>
                                <a className="menu-lateral" href="/administrarTransacciones">Administrar Transacciones TDC</a>
                            </li>
                            <li className={selected === '8' ? 'selected' : 'menu-lateral-li'}
                                onClick={() => handleOptionClick('7')}>
                                <a className="menu-lateral" href="/TransaccionesSPEI">⁠Transacciones SPEI</a>
                            </li>
                            <li className={selected === '9' ? 'selected' : 'menu-lateral-li'}
                                onClick={() => handleOptionClick('7')}>
                                <a className="menu-lateral" href="/NuevaTransacciónSPEI-IN">Nueva Transacción SPEI IN</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MenuLateral
