import React, { useState } from 'react';
import Encrypter from '../../services/Encrypter';
import Message from '../../components/Message/Message';
import Modal from 'react-bootstrap/Modal';

import '../../css/forms.css'
import { postData } from '../../services/Request';

function RecoverPassModal({ show, handleClose, type }) {
  const [mail, setMail] = useState('');

  const [alert, setAlert] = useState({ tipo: '', msn: '', show: false });
  const closeAlert = () => {
    setAlert({ tipo: '', msn: '', show: false });
  };

  const title = 'Recuperar contraseña';
  const text = 'Por favor ingrese su correo registrado con SitePay';

  const handleSendOpsMail = () => {

    const userData = JSON.parse(localStorage.getItem('userData'));
    const token = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, userData.token);
    const encryptedMailType = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, "opsEmail");
    const headers = { headers: { 'authorization': 'APIAuth ' + process.env.REACT_APP_API_KEY + ':' } }
    const Body = {
      email: token,
      type: encryptedMailType
    }

    postData(process.env.REACT_APP_AUTH_RESETEMAIL, Body, headers)
      .then(({ response, error }) => {
        //console.log(response, error);
        const rsp = JSON.parse(response);
        if (response) {
          setAlert({ tipo: 'success', msn: rsp.message, show: true });
          //handleClose();
        } else if (error) {
          const responseErrors = error.response.data.errors.errors[0].message;
          setAlert({ tipo: 'danger', msn: responseErrors, show: true });
          return;
        }

      });

  }


  const handleSendMail = () => {
    if (!mail) {
      setAlert({ tipo: 'danger', msn: 'Favor de introducir una cuenta de correo.', show: true });
      return;
    }

    //TODO: IRIS - meter una validación de REGEX para el email aqui 

    const encryptedMail = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, mail);
    const headers = { headers: { 'authorization': 'APIAuth ' + process.env.REACT_APP_API_KEY + ':' } }
    const Body = {
      email: encryptedMail
    }

    postData(process.env.REACT_APP_AUTH_RESETEMAIL, Body, headers)
      .then(({ response, error }) => {
        //console.log(response, error);
        const rsp = JSON.parse(response);
        if (response) {
          setAlert({ tipo: 'success', msn: rsp.message, show: true });
          //handleClose();
        } else if (error) {
          const responseErrors = error.response.data.errors.errors[0].message;
          setAlert({ tipo: 'danger', msn: responseErrors, show: true });
          return;
        }

      });

  }

  return (
    <div>
      <Modal show={show} onHide={() => { handleClose(); }} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className="modal-title">{type === 'ope' ? `${title} de operaciones` : title}</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Message alert={alert} closeAlert={closeAlert} />
          <div className='container-modal-login'>
            {type === 'ope' ? (
              <>
                <label className="form-label-recover">Le enviaremos un correo con instrucciones para cambiar su contraseña</label>
              </>
            ) : (
              <>
                <label className="form-label-recover">{text}</label>
                <input type="email" className="form-control recPass" aria-describedby="emailHelp" placeholder="email@servidor.com"
                  value={mail}
                  onChange={(e) => setMail(e.target.value)}></input>
              </>)
            }
          </div>
        </Modal.Body>
        <Modal.Footer>
          {type !== 'ope' ?
            (<button onClick={handleSendMail} className="btn btn-primary2 btnModal" type="button" data-bs-dismiss="modal">Enviar correo para recuperar contraseña</button>) :
            (<button onClick={handleSendOpsMail} className="btn btn-primary2 btnModal" type="button" data-bs-dismiss="modal">Enviar correo para recuperar contraseña de operaciones</button>)}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default RecoverPassModal;